.home-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.home-header {
    margin-bottom: 0;
}

.home-section-1-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 300px;
    margin: 0 0 70px 0;
}

.home-section-1-header {
    margin-top: 0;
    margin-bottom: 10px;
}

.home-avatar-container {
    width: 50%;
}

.home-avatar {
    max-width: 292px;
    width: 99%;
    margin-left: .5%;
    margin-right: .5%;
    object-fit: contain;
}

.home-section-1-text-container {
    width: 100%;
}

.home-section-1-text {
    margin-top: 10px;
}

.home-section-1-text-contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    line-height: 17px;
}

.home-section-1-text-contact-container > div{
    width: 145px;
}

.home-section-1-text-contact-container h5 {
    width: 145px;
    width: 285px;
    margin: 0;
    white-space:pre;
    /* text-align: start; */
    justify-content: center;
}

.fw-normal {
    font-weight: normal;
}

.light-link{
    font-weight: 400;
    color: rgb(6, 95, 212);
    text-decoration: none;
}

.dark-link {
    font-weight: 400;
    color: rgb(62, 166, 255);
    text-decoration: none;
}

.home-section-1-text-contact-container .test-3 {
    margin-top: 10px;
}

.test-3 {
    display: flex;
    align-items: center;

    margin-top: 30px;
}

.link-icon {
    color: rgb(17, 82, 147);
    margin: 0px 6px 0px 6px;
}

.icon-linkedin {
    color: rgb(17, 82, 147);
}

.icon-github-dark {
    color: rgb(36, 41, 46);
}

.icon-github-light {
    color: white;
}