.about-container {
    margin:0;
    display: flex;
    flex-direction: column;
}

.about-sect {
    width: 100%;
    display: flex;
    justify-content: center;
}

.about-sect-art {
    background-color: rgba(0,0,0, 0.8);
    color: white;
    border: 3px solid #f1f1f1;
    display: flex;
    flex-direction: row;
    height: 265px;
    justify-content: space-around;
    width: 90%;
    padding: 10px 0 10px 0;
}

.about-sect-art-img {
    height: 100%;
}

.about-separator {
    display: block;
    height: 1px;
    border: 0;
    margin: 1em 30px 1em 30px;
    padding: 0;
}

.separator-light {
    border-top: 1px solid black;
}

.separator-dark {
    border-top: 1px solid lightgrey;
}