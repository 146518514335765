.about-panel-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.about-panel-img-container {
    width: 50%;
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.about-panel-img {
    width: 90%;
    margin: 0 5% 0 5%;
    object-fit: contain;
}

.about-panel-desc-container {
    width: 44%;
    margin-left: 3%;
    margin-right: 3%;
    display: flex;
    align-items: center;
    white-space: pre-wrap;
    /* color: rgb(54, 54, 54); */

    line-height: 1.625em;
    font-size: 16px;
}