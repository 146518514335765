.portfolio-panel-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 858.33px;
    height: 300px;
    margin: 20px 0 70px 0;
}

.portfolio-panel-img-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 48%;
    box-shadow: 0px 0px 21px -2px rgba(0,0,0,0.83);
}

.portfolio-panel-img-bot-wrapper {
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(31, 31, 31, 0.37)
}

.portfolio-panel-img {
    width: 100%;
    object-fit: contain;
    height:  88.33333%;
    flex-grow: 1;
    box-sizing: border-box;
    background-color: black;
    cursor: pointer;
}

.portfolio-nav-arrow {
    opacity: .3;
    background-color: grey;
    cursor: pointer;
}

.portfolio-nav-arrow:hover {
    opacity: .8;
}

.portfolio-info-container {
    width: 48%;
    box-shadow: 0px 0px 21px -4px rgba(0,0,0,0.83);
    box-sizing: border-box;
    padding-left: .2%;
    padding-right: .2%;
    
    overflow: auto;
    scrollbar-width: thin;
}

.portfolio-info-title {
    /* font-size: 32px; */
    margin-top: 21.44px;
    margin-bottom: 21.44px;

    /* font-size: 24px; */
    /* margin-top: 26.56px;
    margin-bottom: 26.56px; */ 
    font-size: 22px;
    /* font-size: 20px; */
}

.portfolio-info-link-wrapper {
    /* margin-bottom: 10px; */
    margin-bottom: 5px;
}

.portfolio-info-link {
    text-decoration: none;
    color: rgb(62, 166, 255);
    font-weight: 400;
}

.portfolio-info-link-note {
    font-weight: 400;
    font-style: italic;
}

.portfolio-info-tech-wrapper {
    margin-top: 0px;
    margin-bottom: 20px;
}

.portfolio-info-tech {
    font-weight: 400;
}

.portfolio-info-desc {
    font-weight: 400;
    font-size: 17px;
    white-space: pre-wrap;
    margin: 0;
}

.portfolio-panel-img-fs-container {
    position: absolute;
    top: 1;
    left: 0;
    width: 100%;
    background-color: rgba(100, 100, 100, 0.9);
    z-index: 100;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    border: 6px solid darkgrey;
    border-radius: 12px;
}

.portfolio-panel-img-fs {
    max-width: 90%;
    cursor: pointer;
}

.portfolio-panel-img-fs-btn-close {
    top: 0;
    right: 0;
    position: absolute;
    padding: 7px 7px 7px 7px;
    background: white;
    opacity: .6;
    color: black;
    border-radius: 5px;
    border: 2px solid orange;
    cursor: pointer;
}

.portfolio-panel-img-fs-btn-close:hover {
    opacity: .9;
}

.dark-link {
    color: rgb(62, 166, 255);
}

.light-link {
    color: rgb(6, 95, 212);
}

@media only screen and (max-width: 900px){
    .portfolio-panel-container {
        display: flex;
        flex-direction: column;
        min-width: auto;
        max-width: 414px;
        height: 600px;
        width: 414px;
        margin-bottom: 40px;
    }

    .portfolio-panel-img-container {
        height: 300px;
        width: 414px;
        align-content: center;
    }

    .portfolio-info-container {
        width: 414px;
        height: 300px;
    }

    .p-shadow-top {
        box-shadow: 0px -3px 21px -2px rgba(0,0,0,0.53);
    }

    .p-shadow-bot {
        box-shadow: 0px 10px 21px -2px rgba(0,0,0,0.53);
    }
}