.nav-container {
    display: inline-flex;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-start;
    font-size: 1rem;
    height: 51px;
}

.nav-icon {
    margin-right: 5px;
}

.theme-icon {
    margin: 0px 15px 0px 5px;
    cursor: pointer;
    align-self: center;
}

.nav-tab {
    padding: 16px 8px 16px 8px;
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
}

.light {
    background-color: rgb(212, 212, 212);
    color: black;
    transition: all 0.25s linear;
}

.dark {
    /* background-color: rgb(75, 75, 75); */
    background-color: #303030;
    color: rgba(255, 255, 255, .9);
    transition: all 0.25s linear;
}

.dark > .nav-active {
    padding-bottom: 13px;
    border-bottom: 3px solid white;
}

.light .nav-active {
    padding-bottom: 13px;
    border-bottom: 3px solid black;
    box-sizing: border-box;
}

.light .nav-tab:hover {
    background-color: white;
}

.dark .nav-tab:hover {
    background-color: rgba(175, 175, 175, 0.445);
}

.theme-toggle-label-dark {
    position: absolute;
    top: 35px;
    opacity: .8;
    background-color: rgb(75, 75, 75);
    border: white 1px solid;
    border-radius: 5px;
    padding: 10px;
    z-index: 200;
    color: rgba(255, 255, 255, .9);
}

.theme-toggle-label-light {
    position: absolute;
    top: 35px;
    opacity: .8;
    background-color: darkgrey;
    border: black 1px solid;
    border-radius: 5px;
    padding: 10px;
    z-index: 200;
}
