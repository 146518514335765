.App {
  width: 100%;
  height: 100%;
  text-align: center;
}

.app-content {
  position: fixed;
  overflow-y: auto;
  height: calc(100% - 53px); /* navbar height */
  width: 100%;
}
